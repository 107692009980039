<i18n>
  {
    "es": {
      "COMPLETE_PROFILE": "Completa tu perfil",
      "COMPLETE": "Guardar perfil",
      "COMPLETING": "Guardando perfil",
      "PROFILE_COMPLETED": "Perfil completado",
      "FIRSTNAME_PLACEHOLDER": "Nombre",
      "LASTNAME_PLACEHOLDER": "Apellidos",
      "DAY_PLACEHOLDER": "Día",
      "MONTH_PLACEHOLDER": "Mes",
      "YEAR_PLACEHOLDER": "Año",
      "FIRSTNAME_REQUIRED": "El nombre es obligatorio",
      "FIRSTNAME_LENGTH": "El nombre debe tener al menos 3 caracteres",
      "LASTNAME_REQUIRED": "Los apellidos son obligatorios",
      "LASTNAME_LENGTH": "Los apellidos deben tener al menos 3 caracteres",
      "DAY_REQUIRED": "El día es obligatorio",
      "DAY_NOT_VALID": "El día no es válido",
      "MONTH_REQUIRED": "El mes es obligatorio",
      "MONTH_NOT_VALID": "El mes no es válido",
      "YEAR_REQUIRED": "El año es obligatorio",
      "YEAR_NOT_VALID": "El año no es válido",
      "PHONE_REQUIRED": "El teléfono es obligatorio",
      "MUST_SELECT_ONE_GENDER": 'Debes seleccionar un género',
      "GENDER_REQUIRED": 'El género es requerido',
      "ERROR": "Ha ocurrido un error",
    },
  }
</i18n>

<template>
  <ModalCourtain :visibility="completeProfile" @close="cancel">
    <template v-slot:title>
      <div
        class="flex flex-col items-center px-4 justify-center w-full bg-white"
      >
        <h2 class="text-xl md:text-2xl font-bodoni font-bold text-center">
          {{ t('COMPLETE_PROFILE') }}
        </h2>
      </div>
    </template>
    <template v-slot:content>
      <form class="space-y-4 md:max-w-xl md:mt-12 mx-auto py-2.5">
        <div
          v-if="loadingData"
          class="w-full h-60 rounded-lg bg-[#d8d8d8]"
        ></div>
        <LabelAnimationInput
          v-else
          required
          type="text"
          id="firstname"
          name="firstname"
          :placeholder="t('FIRSTNAME_PLACEHOLDER')"
          v-model="formData.firstname"
          @change="v$.firstname.$touch()"
          :valid="!v$.firstname.$invalid || formData.firstname == ''"
        />
        <div
          v-if="loadingData"
          class="w-full h-60 rounded-lg bg-[#d8d8d8]"
        ></div>
        <LabelAnimationInput
          v-else
          required
          type="text"
          id="lastname"
          name="lastname"
          :placeholder="t('LASTNAME_PLACEHOLDER')"
          v-model="formData.lastname"
          @change="v$.lastname.$touch()"
          :valid="!v$.lastname.$invalid || formData.lastname == ''"
        />
        <div
          v-if="loadingData"
          class="flex items-start justify-between space-x-2.5"
        >
          <div class="w-86 h-14 rounded-lg bg-[#d8d8d8]"></div>
          <div class="w-full h-60 rounded-lg bg-[#d8d8d8]"></div>
        </div>
        <PhoneInput v-else v-model="formData.phone" />
        <div class="flex items-center justify-between space-x-2.5">
          <div
            v-if="loadingData"
            class="w-full h-60 rounded-lg bg-[#d8d8d8]"
          ></div>
          <LabelAnimationInput
            v-else
            divClass=" w-full"
            required
            type="number"
            id="dob"
            name="dob"
            :placeholder="t('DAY_PLACEHOLDER')"
            v-model="formData.day"
            min="1"
            max="31"
            @change="v$.day.$touch()"
            :valid="!v$.day.$invalid || formData.day == ''"
          />
          <div
            v-if="loadingData"
            class="w-full h-60 rounded-lg bg-[#d8d8d8]"
          ></div>
          <LabelAnimationInput
            v-else
            divClass=" w-full"
            required
            type="number"
            id="mob"
            name="mob"
            :placeholder="t('MONTH_PLACEHOLDER')"
            v-model="formData.month"
            min="1"
            max="12"
            @change="v$.month.$touch()"
            :valid="!v$.month.$invalid || formData.month == ''"
          />
          <div
            v-if="loadingData"
            class="w-full h-60 rounded-lg bg-[#d8d8d8]"
          ></div>
          <LabelAnimationInput
            v-else
            divClass=" w-full"
            required
            type="number"
            id="yob"
            name="yob"
            :placeholder="t('YEAR_PLACEHOLDER')"
            v-model="formData.year"
            min="1900"
            max="9999"
            @change="v$.year.$touch()"
            :valid="!v$.year.$invalid || formData.year == ''"
          />
        </div>
        <div class="flex flex-wrap items-center gap-4 md:gap-8 mt-2">
          <Checkbox
            v-for="gn in genres"
            :key="gn.value"
            :value="gn.value"
            :text="gn.text"
            @select="formData.gender = gn.value"
            :selected="formData.gender == gn.value"
          />
        </div>
      </form>
    </template>
    <template v-slot:footer>
      <div v-if="loadingData" class="w-full h-60 rounded-lg bg-[#d8d8d8]"></div>
      <button
        v-else
        @click="handleCompleteProfile"
        :aria-label="t('COMPLETE')"
        type="submit"
        :disabled="!buttonEnabled"
        class="uppercase bg-others-black bg-opacity-90 text-white mt-10 w-full rounded h-14 font-bold text-sm hover:animate-[slide-in_0.83s] hover:bg-[200%_100%] hover:bg-[linear-gradient(270deg,#212121_50%,#000_0)] animate-[get-light_0.25s] transition-all duration-200 flex justify-center items-center"
        style="background-size: 200% 100%"
        :class="
          buttonEnabled
            ? 'cursor-pointer opacity-100'
            : 'opacity-50 cursor-not-allowed'
        "
      >
        <img
          v-if="loading"
          src="@/assets/svg/icons/icons-loading.svg"
          class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
        />
        <span v-if="loading">{{ t('COMPLETING') }}</span>
        <span v-else>{{ t('COMPLETE') }}</span>
      </button>
    </template>
  </ModalCourtain>
</template>

<script setup>
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, or, helpers } from '@vuelidate/validators'

const { completeProfile, hideCompleteProfileModal } = useAfterSignup()
const { t } = useI18n({
  useScope: 'local',
})

const emit = defineEmits(['close'])

const { addMessage } = useSnackBar()
const route = useRoute()
const router = useRouter()
const { locale } = useI18n()
const loading = ref(false)

const { data: customer, loading: loadingData } = await useLazyAsyncData(() =>
  $fetch('/api/account/personaldata', {
    method: 'GET',
  })
)

const formData = ref({
  firstname: '',
  lastname: '',
  phone: '',
  day: '',
  month: '',
  year: '',
  gender: '',
})

const genres = [
  {
    text: 'Mujer',
    value: 'female',
  },
  {
    text: 'Hombre',
    value: 'male',
  },
  {
    text: 'Sin especificar',
    value: 'not-specified',
  },
]

watch(customer, () => {
  const user = customer.value
  formData.value.firstname = user.firstname
  formData.value.lastname = user.lastname
  if (user.phone) {
    formData.value.phone = user.phone
  }

  if (user.dob) {
    const date = stringToDate(user.dob)

    // Obtenemos el día, mes y año
    formData.value.day = date.getDate()
    formData.value.month = date.getMonth() + 1 // Los meses en JavaScript van de 0 a 11
    formData.value.year = date.getFullYear()
  }

  if (user.gender) {
    formData.value.gender = user.gender
  }
})

const rules = computed(() => {
  return {
    firstname: {
      required: helpers.withMessage(t('FIRSTNAME_REQUIRED'), required),
      minLength: helpers.withMessage(t('FIRSTNAME_LENGTH'), minLength(3)),
    },
    lastname: {
      required: helpers.withMessage(t('LASTNAME_REQUIRED'), required),
      minLength: helpers.withMessage(t('LASTNAME_LENGTH'), minLength(3)),
    },
    day: {
      required: helpers.withMessage(t('DAY_REQUIRED'), required),
      shouldBeChecked: helpers.withMessage(t('DAY_NOT_VALID'), (value) =>
        or(...Array.from({ length: 31 }, (_, i) => i + 1))
      ),
    },
    month: {
      required: helpers.withMessage(t('MONTH_REQUIRED'), required),
      shouldBeChecked: helpers.withMessage(t('MONTH_NOT_VALID'), (value) =>
        or(...Array.from({ length: 12 }, (_, i) => i + 1))
      ),
    },
    year: {
      required: helpers.withMessage(t('YEAR_REQUIRED'), required),
      shouldBeChecked: helpers.withMessage(t('YEAR_NOT_VALID'), (value) =>
        or(
          ...Array.from({ length: 122 }, (_, i) => new Date().getFullYear() - i)
        )
      ),
    },
    gender: {
      required: helpers.withMessage(t('GENDER_REQUIRED'), required),
      shouldBeChecked: helpers.withMessage(
        t('MUST_SELECT_ONE_GENDER'),
        (value) => or('female', 'male', 'not-specified')
      ),
    },
    phone: {
      required: helpers.withMessage(t('PHONE_REQUIRED'), required),
    },
  }
})

const v$ = useVuelidate(rules, formData)

const buttonEnabled = computed(() => {
  if (!v$.value) return false
  v$.value.$validate()

  if (v$.value.$error || v$.value.$invalid) return false

  let day
  let month
  let year
  if (customer.value.dob) {
    const date = stringToDate(customer.value.dob)

    // Obtenemos el día, mes y año
    day = date.getDate()
    month = date.getMonth() + 1 // Los meses en JavaScript van de 0 a 11
    year = date.getFullYear()
  }

  const anyChanges =
    formData.value.firstname != customer.value.firstname ||
    formData.value.lastname != customer.value.lastname ||
    formData.value.phone != customer.value.phone ||
    formData.value.day != day ||
    formData.value.month != month ||
    formData.value.year != year

  return anyChanges
})

onMounted(() => {
  // firstInputRef.value.focus()
})

const handleCompleteProfile = async () => {
  v$.value.$validate()
  if (v$.value.$error) {
    const firstError = v$.value.$errors[0]
    addMessage({
      type: 'error',
      result: 'ERROR',
      text: firstError.$message,
    })
    return
  }
  loading.value = true

  // Convertimos los valores a cadenas y los ajustamos si es necesario
  const dayString = formData.value.day.toString().padStart(2, '0')
  const monthString = formData.value.month.toString().padStart(2, '0')

  const { error } = await useAsyncData(
    `updatePersonalData`,
    () =>
      $fetch('/api/account/personaldata/complete', {
        method: 'POST',
        body: {
          firstname: formData.value.firstname,
          lastname: formData.value.lastname,
          dob: `${dayString}/${monthString}/${formData.value.year}`,
          gender: formData.value.gender ?? customer.value.gender,
          phone: formData.value.phone,
        },
      }),
    {}
  )

  loading.value = false

  if (error.value) {
    handleError(error.value)
  } else {
    hideCompleteProfileModal()
    addMessage({
      type: 'success',
      result: 'OK',
      text: t('PROFILE_COMPLETED'),
    })
  }
}

const cancel = () => {
  hideCompleteProfileModal()
}

const handleError = (error) => {
  console.debug(error)
  addMessage({
    type: 'error',
    result: 'ERROR',
    text: t('ERROR'),
  })
}
</script>
